// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ServiceSection from "../../../../../styleguide/components/ServiceSection/ServiceSection.res.js";
import * as TopSectionsCss from "../../../../../styleguide/home/components/views/index/common/TopSectionsCss.res.js";
import * as Routes_Provider from "../../../../../routes/common/Routes_Provider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexProvider from "../../../../locations/common/index/provider/LocationsIndexProvider.res.js";

function HomeTopProvidersSection(props) {
  var providers = props.providers;
  return JsxRuntime.jsx(ServiceSection.make, {
              title: "Top " + String(providers.length) + " Providers with Data Centers",
              subtitle: "Find providers that offer colocation, bare metal, cloud and managed services.",
              background: "Gray",
              contentDirection: "Column",
              className: TopSectionsCss.serviceSection,
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("div", {
                            children: Belt_Array.mapWithIndex(providers, (function (index, provider) {
                                    return JsxRuntime.jsx(LocationsIndexProvider.make, {
                                                name: provider.name,
                                                slug: provider.slug,
                                                totalLocations: provider.totalLocations
                                              }, String(index));
                                  })),
                            className: TopSectionsCss.gridSection
                          }),
                      JsxRuntime.jsx("a", {
                            children: "View All Providers",
                            className: TopSectionsCss.link,
                            href: Routes_Provider.index
                          })
                    ],
                    className: TopSectionsCss.wrapper
                  })
            });
}

var Css;

var make = HomeTopProvidersSection;

export {
  Css ,
  make ,
}
/* ServiceSection Not a pure module */
