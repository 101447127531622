// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var wrapper = Cx.cx([
      "flex",
      "justify-between",
      "text-sm",
      "border-b",
      "border-solid",
      "border-gray-200",
      "pb-2.5"
    ]);

var name = Cx.cx([
      "max-w-[150px]",
      "overflow-hidden",
      "text-ellipsis",
      "whitespace-nowrap"
    ]);

var totalLocations = Cx.cx(["text-gray"]);

var link = Cx.cx([
      "text-black",
      "font-semibold",
      "hover:text-purple"
    ]);

var container = Cx.cx([
      "flex",
      "flex-col",
      "lg:flex-row",
      "overflow-auto"
    ]);

var container$1 = Cx.cx([
      "flex",
      "flex-col",
      "p-7",
      "sm:p-0"
    ]);

var Locations = {
  container: container$1
};

export {
  wrapper ,
  name ,
  totalLocations ,
  link ,
  container ,
  Locations ,
}
/* wrapper Not a pure module */
